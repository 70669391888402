import { z } from 'zod'

export const CallboxLockSchema = z.object({
  color: z.union([z.literal('red'), z.literal('green')]),
  status: z.string(),
})

export type CallboxLock = z.infer<typeof CallboxLockSchema>

export const CallboxLockUpdateSchema = z.object({
  additionalTime: z.number(),
})

export type CallboxLockUpdate = z.infer<typeof CallboxLockUpdateSchema>
