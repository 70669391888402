export function getLogger() {
  return {
    info: console.log,
    error: console.error,
  }
}

export function getFrontendLogger() {
  return {
    info: (data: object) => {
      fetch(new URL('/api/log', location.origin), {
        method: 'POST',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify({ level: 'info', ...data }),
      }).catch(e => console.error('Error sending info logs API request', e))
    },
    error: (data: object) => {
      fetch(new URL('/api/log', location.origin), {
        method: 'POST',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify({ level: 'error', ...data }),
      }).catch(e => console.error('Error sending info logs API request', e))
    },
  }
}
