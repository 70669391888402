import { Button } from '@mui/material'
import type { ReactNode } from 'react'
import { useState } from 'react'

type BaseButtonProps = Parameters<typeof Button>[0]
export type AsyncButtonProps = BaseButtonProps & {
  doAction: () => Promise<unknown>
  render?: (doingAction: boolean) => ReactNode
}

export function AsyncButton(props: AsyncButtonProps) {
  const [disabled, setDisabled] = useState(false)
  const { doAction, variant, render, ...rest } = props

  return (
    <Button
      disabled={disabled}
      onClick={() => {
        setDisabled(true)
        doAction()
          .catch(e => console.error(e))
          .finally(() => setDisabled(false))
      }}
      variant={variant || 'outlined'}
      {...rest}
    >
      {render?.(disabled) ?? props.children}
    </Button>
  )
}
